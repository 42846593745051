
  <ion-header>
    <ion-toolbar>
      <ion-icon slot="start" (click)="backToTimeline()" class="close-comment-icon" name="close-outline"></ion-icon>
      <ion-title>Finish your post</ion-title>
      <ion-note slot="end" color="dark" (click)="closeModal(postImageForm.value)">Post&nbsp;&nbsp;</ion-note>
    </ion-toolbar>
  </ion-header>
  
  <ion-content *ngIf="show">
    <div class="input-container" *ngIf="src">
      <div id="image-container" *ngIf="isImage">
        <swiper class="mySwiper" effect="fade" [zoom]="true">
          <ng-template swiperSlide>
            <div class="swiper-zoom-container">
              <img class="swiper-lazy" [src]="src" loading="lazy"
                style="background: var(--ion-color-light)" />
            </div>
          </ng-template>
        </swiper>
      </div>
      <div id="video-container" *ngIf="!isImage">
        <vg-player>
          <video (ended)="videoEnd()" #createdVideo [vgMedia]="$any(media)" [vgHls]="src" [poster]="posterImage"
             #media preload="metadata" playsinline webkit-playsinline="webkit-playsinline" muted="true">
            <source [vgHls]="src" type="application/x-mpegURL">
          </video>
        </vg-player>
        <div id="volume-controls">
          <ion-icon *ngIf="muted" name="volume-mute" large (click)="unmuteClicked()"></ion-icon>
          <ion-icon *ngIf="!muted" name="volume-high" large (click)="muteClicked()"></ion-icon>
        </div>
        <div *ngIf="replay" id="replay-controls">
          <p *ngIf="replay" (click)="replayVideo()">Replay video <ion-icon name="refresh-outline" large></ion-icon></p>
        </div>
        <div id="play-controls">
          <ion-icon *ngIf="!pause" name="pause" large (click)="pauseVideo()"></ion-icon>
          <ion-icon *ngIf="pause" name="play" large (click)="playVideo()"></ion-icon>
        </div>
      </div>
      <div class="media-description">
        <form [formGroup]="postImageForm" class="form-wrapper">
          <ion-textarea autoGrow="true" placeholder="Add a description..." class="form-text-area" lines="none"
            #updateMediaInput formControlName="description" maxLength="350" rows="7">
          </ion-textarea>
        </form>
      </div>
    </div>
  </ion-content>

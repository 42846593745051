<ion-tabs mode="md" *ngIf="!needRegister" tabsHightlight="true">
  <ion-tab-bar class="footer-scroll" slot="bottom">
    <ion-tab-button (click)="homeClick()" tab="home">
      <ion-icon *ngIf="!homeclick" name="home-outline"></ion-icon><small>Home</small>
      <ion-icon *ngIf="homeclick" name="home"></ion-icon>
    </ion-tab-button>

    <ion-tab-button (click)="timelineClick()" tab="timeline">
      <ion-icon *ngIf="!timelineclick" name="image-outline"></ion-icon><small>Timeline</small>
      <ion-icon *ngIf="timelineclick" name="image"></ion-icon>
    </ion-tab-button>

    <ion-tab-button (click)="messageClick()" tab="message-board">
      <ion-icon *ngIf="!messageclick" name="people-circle-outline"></ion-icon><small>Hub</small>
      <ion-icon *ngIf="messageclick" name="people-circle"></ion-icon>
    </ion-tab-button>

    <ion-tab-button class="profile-button" (click)="profileClick()" tab="profile/{{username_tab}}">
      <ion-avatar class="profile-pic-avatar">
        <img *ngIf="hasProfilePic" [src]="profilePicture" />
        <img *ngIf="!hasProfilePic" src="../../../assets/profile_placeholder_avatar.png" />
      </ion-avatar><small>Profile</small>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>

<ion-tabs mode="ios" *ngIf="needRegister">
  <ion-tab-bar class="footer-scroll" slot="bottom">
  </ion-tab-bar>
</ion-tabs>

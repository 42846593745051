<ion-header class="ion-no-border" mode="md">
  <ion-toolbar color="light">
      <div class="toolbar-wrapper">
      <ion-title>
        Family Timeline
      </ion-title>
        <input hidden type="file" #filepicker (change)="fileSelected($event)" />
        <ion-fab-button color="dark" (click)="addFile()">
          <ion-icon name="add"></ion-icon>
        </ion-fab-button>
      </div>

  </ion-toolbar>
</ion-header>

<ion-content>
    <!-- loading spinner -->
    <div *ngIf="!loaded" class="loading-centered">
      <ion-spinner name="lines-sharp-small"></ion-spinner>
    </div>
    <app-timeline [timelineRefresh]="timelineRefresh" [data]="timelineData" [token]="nextToken"></app-timeline>
</ion-content>

import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { IonTextarea, ModalController, Platform, ViewDidEnter, ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { BehaviorSubject, Subscription, finalize, timer, Subject, Observable, switchMap, takeUntil } from 'rxjs';
import { APIService, ModelSortDirection } from 'src/app/API.service';
import { SportsService } from 'src/app/services/sports.service';
import { FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { IonContent } from '@ionic/angular';
import { Storage } from '@aws-amplify/storage';
import { DateSuffixPipe } from 'src/app/pipes/date-suffix.pipe';
import { Amplify, Hub } from 'aws-amplify';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';

import { trigger, state, style, animate, transition, query, stagger} from '@angular/animations';

import { Keyboard } from '@capacitor/keyboard';


@Component({
  selector: 'app-baseball-chatroom',
  templateUrl: './baseball-chatroom.page.html',
  styleUrls: ['./baseball-chatroom.page.scss']
})
export class BaseballChatroomPage implements ViewDidEnter, ViewDidLeave, ViewWillEnter {

  onUpdateSportsGame: Subscription | null = null;
  onUpdateHubPost: Subscription | null = null;
  onUpdateChats: Subscription | null = null;
  onCreateBaseballAtBatIndex: Subscription | null = null;
  postChat = {} as FormGroup;
  @ViewChild(IonContent) ionContent: IonContent;
  @ViewChild(IonTextarea) ionTextArea: IonTextarea;
  priorConnectionState: ConnectionState;

  baseballData: any;
  gamePlays: any = [];
  sportsGameID;
  liveGameChatRoomID;
  accordionOpen;
  profilePicture;
  profilePictureImageUrl;
  hasProfilePic: boolean;
  profileUrl;

  userProfileID;
  userUsernameID;
  userTyped;
  userNotTyped;

  chats: any = [];
  livechatroomdata;

  nextToken;
  spinner;
  newChatsSpinner;

  constructor(
    private modalController: ModalController,
    private api: APIService,
    public platform: Platform
  ) { 
    this.postChat = new FormGroup({
      chat: new FormControl('')
    })
  }

  async userTouched(event){
    console.log(event)
  }

  async getChats(livegamechatroomid){
    let response = await this.api.ChatsByLiveGameChatRoomIDAndTimePosted(livegamechatroomid, null, ModelSortDirection.DESC, null, 40).then(data => data);
    this.nextToken = response.nextToken;
    let data = this.sortByTimePosted(response.items)
    return data;
  }

  async sortByTimePosted(array){
    let test = array.sort((a, b) => Date.parse(a.timePosted) - Date.parse(b.timePosted))
    return test;
  }

  ionViewDidLoad(){
    setTimeout(() => {
      this.ionContent.scrollToBottom(200);
    }, 50)
  }

  ionViewWillEnter(){
    document.querySelector<HTMLElement>(".textarea-wrapper").style.minWidth = "100%";
  }

  async ionViewDidEnter() {
    this.spinner = true;
    this.startSubscriptions();
    this.userTyped = false;
    this.liveGameChatRoomID = this.sportsGameID + "-chatroom"
    this.userProfileID = localStorage.getItem('profileID');
    this.userUsernameID = localStorage.getItem('usernameID')
    this.chats = await this.getChats(this.liveGameChatRoomID);
    console.log(this.chats, this.nextToken)
    this.ionViewDidLoad();
    this.userTyped = false;
    this.userNotTyped = true;
    this.profileUrl = await this.getProfilePic();

    if(this.nextToken){
      this.newChatsSpinner = true;
    } else {
      this.newChatsSpinner = false;
    }
    this.spinner = false;
  }

  

  ngAfterViewInit(){
    this.ionContent.scrollToBottom();
  }

  backToHub() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  startSubscriptions() {

    this.onUpdateChats = <Subscription>(
      this.api.OnCreateChatsListener().subscribe({
        next: async (event: any) => {
          let data = event;
          if(data.value.data.onCreateChats.liveGameChatRoomID === this.liveGameChatRoomID){
            const data = event;
            let chats = await this.getChats(data.value.data.onCreateChats.liveGameChatRoomID).then(data => data)
            this.chats = chats;

            if(this.nextToken){
              this.newChatsSpinner = true;
            } else {
              this.newChatsSpinner = false;
            }
  
            setTimeout(() => {
              this.ionContent.scrollToBottom();
            }, 200)
          }
        }
      })
    )
    this.onUpdateSportsGame = <Subscription>(
      this.api.OnUpdateSportsGameListener().subscribe({
        next: async (event: any) => {
          const data = event;
          if (data.value.data.onUpdateSportsGame.id === this.baseballData.id) {
            this.baseballData.currentHalfInning = null;
            this.baseballData = data.value.data.onUpdateSportsGame;
            this.baseballData.gameInfo = JSON.parse(data.value.data.onUpdateSportsGame.gameInfo)
            this.baseballData.gameInfo.currentHalfInning = this.baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress' ? this.baseballData.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(this.baseballData.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", this.baseballData.gameInfo.currentPlay.about.inning.toString()) : null;

            console.log(this.baseballData)

            if (this.baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress') {
              this.baseballData.currentHalfInning = this.baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress' ? this.baseballData.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(this.baseballData.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", this.baseballData.gameInfo.currentPlay.about.inning.toString()) : null;            }
          }
        }
      })
    )
  }


  async createChat(chatMessage) {
    let time = new Date().toISOString()
    await this.api.CreateChats({chat: chatMessage.chat, timePosted: time, profileID: this.userProfileID, usernameID: this.userUsernameID, sortKey: "chat", liveGameChatRoomID: this.liveGameChatRoomID}).then(response => response).finally(() => {
      this.postChat.reset();
    })
    setTimeout(() => {
      this.ionContent.scrollToBottom();
    }, 500)
  }

  async userTouchedEvent(event){
    console.log(event)
    this.ionTextArea.setFocus();
    this.ionContent.scrollToBottom();
  }

  async userTypedEvent(event){
    if(event.detail.value.length > 0){
      document.querySelector<HTMLElement>(".textarea-wrapper").style.minWidth = "85%";
      this.userTyped = true;
    } else {
      document.querySelector<HTMLElement>(".textarea-wrapper").style.minWidth = "100%";
      this.userTyped = false;
    }
  }

  async getProfilePic(){
    this.profilePicture = await this.api.GetProfilePictureProfileID(localStorage.getItem('profileID'));
  
    if(this.profilePicture){
      this.profilePictureImageUrl = this.profilePicture.imageurl;
      this.profilePicture = "https://ik.imagekit.io/bkf4g8lrl/profile-photos/" + this.profilePicture.imageurl;
  
      if(this.profilePicture){
        this.hasProfilePic = true;
      } else {
        this.hasProfilePic = false;
      }
    }
  }

  async ionViewDidLeave() {
    if (this.onUpdateChats) {
      await this.onUpdateChats.unsubscribe();
    }
    if (this.onUpdateHubPost) {
      await this.onUpdateHubPost.unsubscribe();
    }
    if (this.onUpdateSportsGame) {
      await this.onUpdateSportsGame.unsubscribe();
    }
    if (this.onCreateBaseballAtBatIndex) {
      await this.onCreateBaseballAtBatIndex.unsubscribe();
    }
  }

  async loadData(event) {
    this.newChatsSpinner = true;
    try {
      console.log(this.nextToken)
      if (this.nextToken !== null) {
        let livegamechatid = this.sportsGameID + "-chatroom"
        await this.api.ChatsByLiveGameChatRoomIDAndTimePosted(livegamechatid, null, ModelSortDirection.DESC, null, 40, this.nextToken).then(async (data) => {
          let dataPull: any = await this.sortByTimePosted(data.items);
          this.nextToken = data.nextToken;

          console.log(dataPull)

          Object.entries(this.chats).forEach(([key, value]) => { dataPull[dataPull.length] = value })

          this.chats = dataPull;
          event.target.complete();
        })
      } else {
        this.newChatsSpinner = false;
        event.target.complete();
      }
    } catch (error) {
      console.log(error)
    }
  }

  

}

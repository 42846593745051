import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'likeButtonAlready'
})
export class LikeButtonAlreadyPipe implements PipeTransform {

  transform(value: any, currentUsernameID: any): any {
    
    let likesArray = [];
    value.items.map((likes) => {
      likesArray.push(likes.usernameID)
    })
    let boolCheck = likesArray.includes(currentUsernameID)
    return boolCheck;
  }

}

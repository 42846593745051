<ion-header class="ion-no-border" mode="md">
  <ion-toolbar color="light">
    <div class="toolbar-wrapper">
      <ion-title text-left>
        Family Hub
      </ion-title>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="refresh" class="loading-centered">
    <ion-spinner name="lines-sharp-small"></ion-spinner>
  </div>
  <ion-fab slot="fixed" horizontal="end" vertical="bottom">
    <ion-fab-button (click)="openPostModal()" size="small" color="dark">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>

  <ion-card *ngFor="let post of hubData">

    <div *ngIf="post.sportsgame === null && post.postType === 'milestone'">
      <ion-card-header class="sports-update-header">
        <div class="last-updated">
          <ion-note color="dark" class="category-chip">Milestone</ion-note>
          <ion-label (click)="presentActionSheet(post.postDetails.profilePic.profileID, post.id, post.postDetails.postImagePresent, post.postDetails.src)" class="top-card" slot="end"><ion-icon name="ellipsis-horizontal-sharp"></ion-icon></ion-label>
        </div>
      </ion-card-header>
      <div class="post-wrapper-general">
        <div class="text-wrapper">
          <ion-avatar><img src="https://ik.imagekit.io/bkf4g8lrl/profile-photos/{{post.postDetails.profilePic.imageurl}}" /></ion-avatar>
          <div class="text-det-wrapper">
            <div class="text-username-time-wrapper">
              <ion-note color="dark"><strong>{{post.postDetails.username}}</strong>&nbsp;</ion-note>
              <ion-note class="live-updates" color="medium">~&nbsp;{{post.timePosted | dateAsAgo}}</ion-note>
            </div>
            <ion-note color="dark">{{post.postDetails.postText}}</ion-note>
          </div>
        </div>
        <div class="image-wrapper" *ngIf="post.postDetails.postImagePresent">
          <div class="empty-div-img"></div>
          <img src="https://ik.imagekit.io/bkf4g8lrl/hub-photos/{{post.postDetails.src}}" />
        </div>
      </div>
      <div class="join-conversation">
        <app-hubpost-like-button [alreadyLiked]="post.likes | likeButtonAlready:currentUserUsernameID" [hubPostId]="post.id"></app-hubpost-like-button>
        <div class="likes-bar">
          <ion-note color="dark" *ngIf="post.likes.items.length < 1">0</ion-note>
          <ion-note color="dark" class="likes-count" *ngIf="post.likes.items.length >= 1">{{post.likes.items.length}}</ion-note>
        </div>
        <!-- comment chat-bubble button below image -->
        <ion-tab-button (click)="openHubCommentModal(post.id)">
          <ion-icon name="chatbubble-outline" size="large"></ion-icon>
        </ion-tab-button>
        <div class="comments-bar">
          <ion-note color="dark" *ngIf="post.comments.items.length < 1">0</ion-note>
          <ion-note color="dark" class="comments-count" *ngIf="post.comments.items.length >= 1">{{post.comments.items.length}}</ion-note>
        </div>
      </div>

    </div>

    <div *ngIf="post.sportsgame === null && post.postType === 'sport'">
      <ion-card-header class="sports-update-header">
        <div class="last-updated">
          <ion-note color="dark" class="category-chip">Sports</ion-note>
          <ion-note class="live-updates" color="medium">{{post.timePosted | dateAsAgo}}</ion-note>
        </div>
      </ion-card-header>
      <div class="post-wrapper" *ngFor="let team of post.postDetails | oriolesFilter">
        <ion-avatar *ngIf="team.team === 'Baltimore Orioles'"><img alt="team-logo-away" src="https://midfield.mlbstatic.com/v1/team/110/spots/120" /></ion-avatar>
        <ion-note *ngIf="team.team === 'Baltimore Orioles' && team.divisionRank === '1'" color="dark">The <strong>Baltimore Orioles ({{team.wins}}-{{team.losses}})</strong> are currently <strong>{{team.standingsPrefix}} in the AL East</strong>.&nbsp;🎉</ion-note>
        <ion-note *ngIf="team.team === 'Baltimore Orioles' && team.divisionRank !== '1'" color="dark">The <strong>Baltimore Orioles ({{team.wins}}-{{team.losses}})</strong> are currently <strong>{{team.standingsPrefix}} in the AL East</strong> and <strong>{{team.divisionGamesBack}} games back</strong> from first place in the division.</ion-note>
      </div>
      <ion-grid class="standings-table">
        <ion-row class="header-row">
          <ion-col>Team</ion-col>
          <ion-col>W</ion-col>
          <ion-col>L</ion-col>
          <ion-col>PCT</ion-col>
          <ion-col>GB</ion-col>
        </ion-row>
        <ion-row *ngFor="let teams of post.postDetails" class="team-data">
          <ion-col class="logo"><ion-avatar><img alt="team-logo-away" src="https://midfield.mlbstatic.com/v1/team/{{teams.imageId}}/spots/120" /></ion-avatar></ion-col>
          <ion-col>{{teams.wins}}</ion-col>
          <ion-col>{{teams.losses}}</ion-col>
          <ion-col>{{teams.pct}}</ion-col>
          <ion-col>{{teams.divisionGamesBack}}</ion-col>
        </ion-row>
      </ion-grid>
      <div class="join-conversation">
        <app-hubpost-like-button [alreadyLiked]="post.likes | likeButtonAlready:currentUserUsernameID" [hubPostId]="post.id"></app-hubpost-like-button>
        <div class="likes-bar">
          <ion-note color="dark" *ngIf="post.likes.items.length < 1">0</ion-note>
          <ion-note color="dark" class="likes-count" *ngIf="post.likes.items.length >= 1">{{post.likes.items.length}}</ion-note>
        </div>
        <!-- comment chat-bubble button below image -->
        <ion-tab-button (click)="openHubCommentModal(post.id)">
          <ion-icon name="chatbubble-outline" size="large"></ion-icon>
        </ion-tab-button>
        <div class="comments-bar">
          <ion-note color="dark" *ngIf="post.comments.items.length < 1">0</ion-note>
          <ion-note color="dark" class="comments-count" *ngIf="post.comments.items.length >= 1">{{post.comments.items.length}}</ion-note>
        </div>
      </div>
    </div>

    <!-- baseball card start -->
    <div *ngIf="post.sportsgame !== null && post.sportsgame.sport === 'baseball'">
      <ion-progress-bar *ngIf="post.sportsgame.gameInfo.gameStarted && post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'Final'" type="indeterminate"></ion-progress-bar>
      <ion-card-header>
        <div class="last-updated">
          <ion-note class="category-chip">Sports</ion-note>
          <ion-note class="live-updates" *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'Final'" color="medium">Last updated at {{post.sportsgame.lastUpdate | date: 'h:mma'}} ET</ion-note>
          <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Final' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Game Over' && post.sportsgame.gameInfo.oriolesOutcome" color="medium" class="orioles-outcome">{{post.sportsgame.gameInfo.oriolesOutcome}}</ion-note>
        </div>
        <div class="sports-banner">
          <div class="sports-teams">
            <div class="team-wrapper-away">
                <ion-avatar>
                  <img alt="team-logo-away" src="https://midfield.mlbstatic.com/v1/team/{{post.sportsgame.gameInfo.initialGameData.teams.away.id}}/spots/120" />
                </ion-avatar> 
                <div class="team-info-away">
                  <ion-note class="record">{{post.sportsgame.gameInfo.initialGameData.teams.away.record.wins}} - {{post.sportsgame.gameInfo.initialGameData.teams.away.record.losses}}</ion-note>
                  <ion-note *ngIf="post.sportsgame.gameInfo.gameStarted" class="score" color="dark">{{post.sportsgame.gameInfo.currentPlay.result.awayScore}}</ion-note>
                </div>
            </div>
            <div class="bases-outs">
              <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Warmup'">{{post.sportsgame.gameInfo.currentHalfInning}}</ion-note>
              <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Final' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Game Over'" class="final" color="dark">{{post.sportsgame.startTime | date: 'E M/d' }} <br> Final</ion-note>
              <ion-note *ngIf="!post.sportsgame.gameInfo.gameStarted" class="final" color="dark">{{post.sportsgame.startTime | date: 'E M/d' }} <br> {{post.sportsgame.startTime | date: 'h:mm a'}} ET</ion-note>
              <ion-avatar *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Warmup'">
                <img *ngIf="!post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst && !post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond && !post.sportsgame.gameInfo.currentPlay.matchup.postOnThird" src="../../../assets/baseball-data/bases-clear.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst && (!post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond && !post.sportsgame.gameInfo.currentPlay.matchup.postOnThird)" src="../../../assets/baseball-data/runner-on-first-only.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond && (!post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst && !post.sportsgame.gameInfo.currentPlay.matchup.postOnThird)" src="../../../assets/baseball-data/runner-second-only.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.matchup.postOnThird && (!post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst && !post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond)" src="../../../assets/baseball-data/runner-third-only.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst && post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond && !post.sportsgame.gameInfo.currentPlay.matchup.postOnThird" src="../../../assets/baseball-data/runners-first-second.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond && post.sportsgame.gameInfo.currentPlay.matchup.postOnThird && !post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst" src="../../../assets/baseball-data/runners-second-third.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst && post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond && post.sportsgame.gameInfo.currentPlay.matchup.postOnThird" src="../../../assets/baseball-data/bases-loaded.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.matchup.postOnFirst && post.sportsgame.gameInfo.currentPlay.matchup.postOnThird && !post.sportsgame.gameInfo.currentPlay.matchup.postOnSecond" src="../../../assets/baseball-data/runners-corners.svg" />
              </ion-avatar>
              <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Warmup'" class="current-count">{{post.sportsgame.gameInfo.currentPlay.count.balls}} - {{post.sportsgame.gameInfo.currentPlay.count.strikes}}</ion-note>
              <ion-avatar *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Warmup'">
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.count.outs === 0" src="../../../assets/baseball-data/no-outs.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.count.outs === 1" src="../../../assets/baseball-data/one-out.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.count.outs === 2" src="../../../assets/baseball-data/two-outs.svg" />
                <img *ngIf="post.sportsgame.gameInfo.currentPlay.count.outs === 3" src="../../../assets/baseball-data/three-outs.svg" />
              </ion-avatar>
            </div>
            <div class="team-wrapper-home">
                <ion-avatar>
                  <img alt="team-logo-home" src="https://midfield.mlbstatic.com/v1/team/{{post.sportsgame.gameInfo.initialGameData.teams.home.id}}/spots/120" />
                </ion-avatar> 
                <div class="team-info-home">
                  <ion-note class="record">{{post.sportsgame.gameInfo.initialGameData.teams.home.record.wins}} - {{post.sportsgame.gameInfo.initialGameData.teams.home.record.losses}}</ion-note>
                  <ion-note *ngIf="post.sportsgame.gameInfo.gameStarted" class="score" color="dark">{{post.sportsgame.gameInfo.currentPlay.result.homeScore}}</ion-note>
                </div>
            </div>
          </div>
        </div>
      </ion-card-header>

      <div class="live-board">
        <div class="current-pitcher-wrapper" *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' && post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'Final'">
          <ion-card-subtitle>Current Pitcher</ion-card-subtitle>
          <div class="current-pitcher-data">
            <ion-avatar class="headshot-live"><img src="https://midfield.mlbstatic.com/v1/people/{{post.sportsgame.gameInfo.currentPlay.matchup.pitcher.id}}/spots/120" /></ion-avatar>
            <ion-note class="current-pitcher-stats"><strong style="color:var(--ion-color-medium)">{{post.sportsgame.gameInfo.currentPlay.matchup.pitcher.fullName}}</strong><br>P: {{post.sportsgame.gameInfo.currentPitcherStats.pitchCount}} (S: {{post.sportsgame.gameInfo.currentPitcherStats.strikesThrown}}), IP: {{post.sportsgame.gameInfo.currentPitcherStats.inningsPitched}} <br> ER: {{post.sportsgame.gameInfo.currentPitcherStats.earnedRuns}}, K: {{post.sportsgame.gameInfo.currentPitcherStats.strikeOuts}}, BB: {{post.sportsgame.gameInfo.currentPitcherStats.walks}} </ion-note>
          </div>
        </div>
        <div class="current-batter-wrapper" *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' && post.sportsgame.gameInfo.initialGameData.status.detailedState!== 'Final'">
          <ion-card-subtitle>Current Batter</ion-card-subtitle>
          <div class="current-batter-data">
            <ion-avatar class="headshot-live"><img src="https://midfield.mlbstatic.com/v1/people/{{post.sportsgame.gameInfo.currentPlay.matchup.batter.id}}/spots/120" /></ion-avatar>
            <ion-note class="current-batter-stats" *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress'"><strong style="color:var(--ion-color-medium)">{{post.sportsgame.gameInfo.currentPlay.matchup.batter.fullName}}</strong><br>{{post.sportsgame.gameInfo.currentBatterStats.summary}} <br> AVG: {{post.sportsgame.gameInfo.currentBatterStats.avg}}, OPS: {{post.sportsgame.gameInfo.currentBatterStats.ops}}</ion-note>
          </div>
        </div>
      </div>

      <div class="pitcher-duel" *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'Final' && (post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Scheduled' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Pre-Game' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Warmup')">
        <ion-card-subtitle>Starting Pitchers</ion-card-subtitle>
        <div class="pregame-pitchers">
          <div class="left-side">
            <ion-avatar class="headshot-live"><img src="https://midfield.mlbstatic.com/v1/people/{{post.sportsgame.gameInfo.initialGameData.probablePitchers.away.id}}/spots/120"/></ion-avatar>
            <div class="stats-wrapper" *ngIf="(post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Scheduled' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Pre-Game' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Warmup')">
              <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.probablePitchers.away"><strong>{{post.sportsgame.gameInfo.initialGameData.probablePitchers.away.fullName}}</strong></ion-note>
              <ion-note *ngIf="post.sportsgame.gameInfo.probablePitchersAwayStats">{{post.sportsgame.gameInfo.probablePitchersAwayStats.wins}}-{{post.sportsgame.gameInfo.probablePitchersAwayStats.losses}} | ERA: {{post.sportsgame.gameInfo.probablePitchersAwayStats.era}}</ion-note>
              <ion-note *ngIf="post.sportsgame.gameInfo.probablePitchersAwayStats">WHIP: {{post.sportsgame.gameInfo.probablePitchersAwayStats.whip}}</ion-note>
            </div>
          </div>
          <div class="right-side">
            <ion-avatar class="headshot-live" *ngIf="post.sportsgame.gameInfo.initialGameData.probablePitchers.home"><img src="https://midfield.mlbstatic.com/v1/people/{{post.sportsgame.gameInfo.initialGameData.probablePitchers.home.id}}/spots/120" /></ion-avatar>
            <ion-avatar class="headshot-live" *ngIf="!post.sportsgame.gameInfo.initialGameData.probablePitchers.home"><img src="../../../assets/avatar.svg" /></ion-avatar>
            <div class="stats-wrapper">
              <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'In Progress' && post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'Final' && !post.sportsgame.gameInfo.initialGameData.probablePitchers.home">TBD</ion-note>
              <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'In Progress' && post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'Final' && post.sportsgame.gameInfo.initialGameData.probablePitchers.home"><strong>{{post.sportsgame.gameInfo.initialGameData.probablePitchers.home.fullName}}</strong></ion-note>
              <ion-note *ngIf="post.sportsgame.gameInfo.probablePitchersHomeStats">{{post.sportsgame.gameInfo.probablePitchersHomeStats.wins}}-{{post.sportsgame.gameInfo.probablePitchersHomeStats.losses}} | ERA: {{post.sportsgame.gameInfo.probablePitchersHomeStats.era}}</ion-note>
              <ion-note *ngIf="post.sportsgame.gameInfo.probablePitchersHomeStats">WHIP: {{post.sportsgame.gameInfo.probablePitchersHomeStats.whip}}</ion-note>
            </div>
          </div>
        </div>

        <div class="pregame-pitchers" *ngIf="pitcherInfo == 0">
          <div class="left-side">
            <ion-avatar class="tbd-avatar-left"><img src="../../../assets/avatar.svg" /></ion-avatar>
            <div class="stats-wrapper">
              <ion-note>TBD</ion-note>
            </div>
          </div>
          <div class="right-side">
            <div class="stats-wrapper">
              <ion-note>TBD</ion-note>
            </div>
            <ion-avatar class="tbd-avatar-right"><img src="../../../assets/avatar.svg" /></ion-avatar>
          </div>
        </div>
      </div>
      <div class="final-pitchers" *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Final' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Game Over' && post.sportsgame.gameInfo.finalData">
        <div class="duel">
          <div class="left-side">
            <div class="name-pic-wrapper">
              <ion-avatar class="headshot"><img src="https://midfield.mlbstatic.com/v1/people/{{post.sportsgame.gameInfo.finalData.winner.id}}/spots/120" /></ion-avatar>
              <ion-card-subtitle class="final-stats">{{post.sportsgame.gameInfo.finalData.winner.fullName.split(" ").slice(-1).join(' ')}}</ion-card-subtitle>
              <ion-note class="record">{{post.sportsgame.gameInfo.winningPitcherStats.note}}</ion-note>
            </div>
            <ion-note class="game-stats">IP {{post.sportsgame.gameInfo.winningPitcherStats.inningsPitched}}, ER {{post.sportsgame.gameInfo.winningPitcherStats.earnedRuns}}, K {{post.sportsgame.gameInfo.winningPitcherStats.strikeOuts}}<br> BB {{post.sportsgame.gameInfo.winningPitcherStats.baseOnBalls}}, P {{post.sportsgame.gameInfo.winningPitcherStats.pitchesThrown}}</ion-note>
          </div>
          <div class="right-side">
            <div class="name-pic-wrapper">
              <ion-avatar class="headshot"><img src="https://midfield.mlbstatic.com/v1/people/{{post.sportsgame.gameInfo.finalData.loser.id}}/spots/120" /></ion-avatar>
              <ion-card-subtitle class="final-stats">{{post.sportsgame.gameInfo.finalData.loser.fullName.split(" ").slice(-1).join(' ')}}</ion-card-subtitle>
              <ion-note class="record">{{post.sportsgame.gameInfo.losingPitcherStats.note}}</ion-note>
            </div>
            <ion-note class="game-stats">IP {{post.sportsgame.gameInfo.losingPitcherStats.inningsPitched}}, ER {{post.sportsgame.gameInfo.losingPitcherStats.earnedRuns}}, K {{post.sportsgame.gameInfo.losingPitcherStats.strikeOuts}}<br> BB {{post.sportsgame.gameInfo.losingPitcherStats.baseOnBalls}}, P {{post.sportsgame.gameInfo.losingPitcherStats.pitchesThrown}}</ion-note>
          </div>
        </div>
      </div>
      <div class="venue" *ngIf="post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Scheduled' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Pre-Game' || post.sportsgame.gameInfo.initialGameData.status.detailedState === 'Warmup'">
        <div class="left-side">
          <ion-note>{{post.sportsgame.gameInfo.initialGameData.venue.name}}<br> {{post.sportsgame.gameInfo.initialGameData.venue.location.city}}, {{post.sportsgame.gameInfo.initialGameData.venue.location.stateAbbrev}}</ion-note>
        </div>
        <div class="right-side">
          <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.weather.temp && post.sportsgame.gameInfo.initialGameData.weather.condition">{{post.sportsgame.gameInfo.initialGameData.weather.temp}}&#xb0;F <br>{{post.sportsgame.gameInfo.initialGameData.weather.condition}}</ion-note>
          <ion-note *ngIf="post.sportsgame.gameInfo.initialGameData.weather === 'TBD'">Weather <br> TBD</ion-note>
        </div>
      </div>


      <div *ngIf="post.sportsgame.gameInfo.gameStarted && post.sportsgame.gameInfo.initialGameData.status.detailedState !== 'Final'" class="game-status">
        <ion-card-subtitle>Latest Plays</ion-card-subtitle>
        <div class="latest-plays" *ngFor="let play of post.sportsgame.gameInfo.latestPlays">
          <div class="play" *ngIf="play.result.description">
            <div class="play-wrapper-bottom" *ngIf="play.result.endInning">
              <ion-card-subtitle class="play-text" *ngIf="play.result.endInning">{{play.result.endInning}}</ion-card-subtitle>
            </div>
            <div class="play-wrapper-top">
              <ion-icon name="chevron-forward-outline" *ngIf="play.result.description && !play.result.isOut && !play.about.isScoringPlay"></ion-icon><ion-note *ngIf="play.result.description && !play.result.isOut && !play.about.isScoringPlay">{{play.result.description}}</ion-note>
              <ion-icon name="chevron-forward-outline" *ngIf="play.result.isOut && play.result.description && !play.about.isScoringPlay"></ion-icon><ion-note *ngIf="play.result.isOut && play.result.description && !play.about.isScoringPlay">{{play.result.description}}<span class="outs">&nbsp;{{play.count.outs}} out(s).</span></ion-note>
              <ion-icon name="chevron-forward-outline" *ngIf="play.result.isOut && play.about.isScoringPlay && play.result.description"></ion-icon><ion-note *ngIf="play.result.isOut && play.about.isScoringPlay && play.result.description">{{play.result.description}}<span class="outs">&nbsp;{{play.count.outs}} out(s).&nbsp;{{post.sportsgame.gameInfo.initialGameData.teams.away.abbreviation}} {{play.result.awayScore}} - {{post.sportsgame.gameInfo.initialGameData.teams.home.abbreviation}} {{play.result.homeScore}}</span></ion-note>
              <ion-icon name="chevron-forward-outline" *ngIf="!play.result.isOut && play.about.isScoringPlay && play.result.description"></ion-icon><ion-note *ngIf="!play.result.isOut && play.about.isScoringPlay && play.result.description">{{play.result.description}}<span class="outs">&nbsp;{{post.sportsgame.gameInfo.initialGameData.teams.away.abbreviation}} {{play.result.awayScore}} - {{post.sportsgame.gameInfo.initialGameData.teams.home.abbreviation}} {{play.result.homeScore}}</span></ion-note>
            </div>
          </div>
        </div>
      </div>
      <div class="join-conversation" *ngIf="post.sportsgame.gameInfo.gameStarted">
        <ion-chip (click)="openBaseballChatroom(post.sportsgame, post.sportsgame.id)" color="dark">Join the conversation<ion-icon size="small" name="chevron-forward-outline"></ion-icon></ion-chip>
      </div>
    </div>
    <!-- baseball card end -->
  </ion-card> 

  <ion-infinite-scroll *ngIf="!scrollFinished" threshold="0%" (ionInfinite)="loadData($event)" position="bottom">
    <ion-infinite-scroll-content loadingSpinner="lines-sharp-small">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <div *ngIf="scrollFinished" class="finished-scrolling">
    <ion-icon color="medium" name="checkmark-circle-outline"></ion-icon>&nbsp;
    <ion-text color="medium">You are all caught up</ion-text>
  </div>


</ion-content>



<!-- pull and refresh option at top -->
<ion-content class="no-scroll" [fullscreen]="true" [scrollEvents]="true" (ionScroll)="didScroll()" id="wall-content" lines="none" #container>

  <div *ngIf="refresh" class="spinner-test" style="height: 5em; border-bottom: 1px solid var(--ion-color-light); display: flex; justify-content: center; align-items: center;">
      <ion-spinner name="lines-sharp-small"></ion-spinner>
  </div>


  <!-- subscription to listen for new posts, will show pop-up when new posts added to wall -->
  <div class="new-post-button-div">
    <ion-fab *ngIf="showFabButton" vertical="top" slot="fixed">
      <ion-fab-button (click)="seeNewPostsButton()" color="tertiary">See new posts&nbsp;<ion-icon size="small" name="arrow-up-outline"></ion-icon></ion-fab-button>
    </ion-fab>
  </div>

  <!-- start of photo grid -->
  <ion-grid lines="none" class="ion-no-padding" mode="ios">
    <ion-row lines="none" class="ion-padding-vertical" mode="ios">

      <!-- start of iterating through images -->
      <ion-col lines="none" *ngFor="let image of data" size="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" trackBy:trackItems>

        <!-- filter out any posts that were deleted -->
        <ion-card lines="none" *ngIf="image.postDeleted !== true">

          <!-- display the username and settings for each post at top of card -->
          <ion-item lines="none" mode="ios">
            <div class="top-wrapper">
              <div class="start-top-wrapper">
                <ion-avatar *ngIf="!image.profile.profilepicture.imageurl" class="upload-pic-container">
                  <img id="avatar" src="../../../assets/avatar.svg">
                </ion-avatar>
                <ion-avatar *ngIf="image.profile.profilepicture.imageurl" class="upload-pic-container">
                  <img id="avatar" src="https://ik.imagekit.io/bkf4g8lrl/profile-photos/{{image.profile.profilepicture.imageurl}}">
                </ion-avatar>
                <ion-label class="top-card"><small class="card-username" routerLink="/profile/{{image.username.username}}">{{image.username.username}}</small></ion-label>
              </div>
              <ion-label (click)="presentActionSheet(image.username, image.id, image.s3_key, image.downloadableVideo, image.isVideo)" class="top-card" slot="end"><ion-icon name="ellipsis-horizontal-sharp"></ion-icon></ion-label>
            </div>
          </ion-item>

          <!-- locate the image url and assign a unique id per post -->
          <ion-card-header lines="none" mode="ios">
            <swiper *ngIf="!image.isVideo" class="mySwiper" effect="fade" [zoom]="true">
              <ng-template swiperSlide>
                <div class="swiper-zoom-container">
                  <img *ngIf="!image.isVideo && mobilePlatform" id="photo_{{image.imagesID}}" class="swiper-lazy" [src]="image.mediaSourceMobile" loading="lazy" style="background: var(--ion-color-light)"/>
                  <img *ngIf="!image.isVideo && !mobilePlatform" id="photo_{{image.imagesID}}" class="swiper-lazy" [src]="image.mediaSourceDesktop" loading="lazy" style="background: var(--ion-color-light)" />
                </div>
              </ng-template>
            </swiper>
            <div id="video-container" *ngIf="!image.mediaSourceDesktop && !image.mediaSourceMobile">
              <vg-player>
                <video (ended)="videoEnd()" #timelineVideo class="video_{{image.id}}" [vgMedia]="$any(media)" [poster]="image.posterImage" posterelement="#test" vgHls="https://d2glij88atjbas.cloudfront.net/public/{{image.s3_key}}" #media preload="none" crossorigin playsinline webkit-playsinline="webkit-playsinline" muted="true">
                  <source vgHls="https://d2glij88atjbas.cloudfront.net/public/{{image.s3_key}}" type="application/x-mpegURL">
                </video>
                <vg-scrub-bar *ngIf="!image.mediaSourceDesktop && !image.mediaSourceMobile" [vgSlider]="true">
                  <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                  <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                </vg-scrub-bar>
              </vg-player>
              <div id="volume-controls" *ngIf="!image.mediaSourceDesktop && !image.mediaSourceMobile">
                <ion-icon *ngIf="muted" name="volume-mute" large (click)="unmuteClicked()"></ion-icon>
                <ion-icon *ngIf="!muted" name="volume-high" large (click)="muteClicked()"></ion-icon>
              </div>
              <div *ngIf="replay" id="replay-controls">
                <p *ngIf="replay" (click)="replayVideo()">Replay video</p>
              </div>
              <div id="play-controls" *ngIf="!image.mediaSourceDesktop && !image.mediaSourceMobile">
                <ion-icon *ngIf="!pause" name="pause" large (click)="pauseVideo()"></ion-icon>
                <ion-icon *ngIf="pause" name="play" large (click)="playVideo()"></ion-icon>
              </div>
            </div>
            <ion-list lines="none" mode= "md">
              <ion-tab-bar lines="none" id="interaction_{{image.imagesID}}" class="interactions-photos">

                <!-- custom component for like button, uses pipe to see if like button already pressed by user per image -->
                <app-timeline-like-button [alreadyLiked]="image.likes | likeButtonAlready:currentUserUsernameID" [imageId]="image.id"></app-timeline-like-button>
                <!-- shows the number of likes for each post -->
                <div class="likes-bar">
                  <small *ngIf="image.likes.items.length < 1">0</small>
                  <small class="likes-count" (click)="showLikesModal([image.url, image.id, image.imagesID])"
                    *ngIf="image.likes.items.length >= 1">{{image.likes.items.length}}</small>
                </div>
                <!-- comment chat-bubble button below image -->
                <ion-tab-button (click)="openCommentModal(image.id)">
                  <ion-icon name="chatbubble-outline" size="large"></ion-icon>
                </ion-tab-button>

                <div class="comments-bar">
                  <small *ngIf="image.comments.items.length < 1">0</small>
                  <small class="comments-count" *ngIf="image.comments.items.length >= 1">{{image.comments.items.length}}</small>
                </div>

                <!-- <ion-tab-button *ngIf="disabledButtons" disabled="true" class="disabledCommentButton" (click)="openModal(image.id)">
                  <p class="comment-icon">Comment</p><ion-icon name="chatbubbles-outline"></ion-icon>
                </ion-tab-button> -->

              </ion-tab-bar>
            </ion-list>

            <!-- shows the post description and the user that posted the image -->
            <ion-card-content class="ion-no-padding" lines="none" class="image-description">
              <strong class="username-description" routerLink="/profile/{{image.username.username}}">{{image.username.username}}</strong> {{image.description}}
              <ion-card-subtitle color="medium" class="date-ago-text" lines="none"><small>{{image.time_posted | dateAsAgo}}</small></ion-card-subtitle>
            </ion-card-content>

          </ion-card-header>

          <!-- start of comment section of post -->
          <ion-item-group lines="none" class="comments" *ngIf="image.comments.items.length > 0">
            <!-- if greater than 0 comments, show below -->
            <ion-item-divider lines="none" class="comment-divider">
              <ion-label class="view-all-comments" color="medium" (click)="openCommentModal(image.id)">View all comments ({{image.comments.items.length}}) </ion-label>
            </ion-item-divider>
          </ion-item-group>
        </ion-card>
      </ion-col>
    </ion-row>
    <div *ngIf="scrollFinished" class="finished-scrolling">
      <ion-icon color="medium" name="checkmark-circle-outline"></ion-icon>&nbsp;
      <ion-text color="medium">You are all caught up</ion-text>
    </div>
  </ion-grid>

  <ion-infinite-scroll *ngIf="mobilePlatform && !profileSearch" threshold="250%" (ionInfinite)="loadData($event)" position="bottom">
    <ion-infinite-scroll-content loadingSpinner="lines-sharp-small">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

  <ion-infinite-scroll *ngIf="!mobilePlatform && !profileSearch" threshold="150%" (ionInfinite)="loadData($event)" position="bottom">
    <ion-infinite-scroll-content loadingSpinner="lines-sharp-small">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>




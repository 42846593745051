import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { APIService } from '../../API.service';
import { FA } from 'src/app/FA.service';
import { MediaService } from 'src/app/services/media.service';
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { ModalController, Platform, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-hubpost-like-button',
  templateUrl: './hubpost-like-button.component.html',
  styleUrls: ['./hubpost-like-button.component.scss'],
})
export class HubpostLikeButtonComponent implements OnInit {

  @Input() alreadyLiked: boolean;
  @Input() hubPostId: string;
  @Output() liked: EventEmitter<boolean> = new EventEmitter();
  onUpdateHubPostSubscription: Subscription | null = null;
  likeclicked;
  onCreateLikesSubscription: Subscription | null = null;

  constructor(
    public api: APIService,
    public mediaService: MediaService,
    public platform: Platform,
    public fa: FA
  ) { }

  ngOnInit() {}

  async likeEvent(id) {
    if (this.platform.is('hybrid')) {
      await Haptics.impact({ style: ImpactStyle.Heavy })
    }

    this.likeclicked = true;
    let userData: any = await this.api.ListHubLikes({ and: [{ hubPostID: { eq: id }, usernameID: { eq: localStorage.getItem('usernameID') } }] }).then((data) => data);
    let userLiked = userData.items.length;

    if (userLiked > 0) {
      let likeID = userData.items[0].id;
      await this.api.DeleteHubLikes({ id: likeID }).then(() => {
        this.alreadyLiked = !this.alreadyLiked;
        this.liked.emit(!this.alreadyLiked)
        this.likeclicked = false;
      })
    } else {
      await this.api.CreateHubLikes({ "usernameID": localStorage.getItem('usernameID'), "profileID": localStorage.getItem('profileID'), "hubPostID": id }).then(() => {
        this.alreadyLiked = !this.alreadyLiked;
        this.liked.emit(this.alreadyLiked)
        this.likeclicked = false;
      })
    }
  }
  

}

<ion-header>
  <ion-toolbar>
    <ion-icon slot="start" (click)="closeModal()" class="close-comment-icon" name="close-outline"></ion-icon>
    <ion-title>Add To Hub</ion-title>
    <ion-note color="dark" slot="end" *ngIf="validEntry" (click)="submitPost()">Post</ion-note>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item class="memory" counter="true">
    <ion-note>Do you have a special milestone that you'd like to share with the family? It could be a birthday, anniversary, or any sort of significant life achievement that you'd like to recognize.</ion-note>
    <div class="text-area-wrapper">
      <ion-label class="name-input" position="stacked">Milestone</ion-label>
      <ion-textarea (ionChange)="userTyped($event)" autoGrow="true" placeholder="Tell us about your milestone..." class="memory" maxlength="280" shape="round"></ion-textarea>
      <div class="add-photo-wrapper">
        <input hidden type="file" accept="image/*" #photopicker (change)="fileSelected($event)" />
        <ion-chip (click)="addFile()" color="dark" [outline]="true">Add a photo<ion-icon name="image" size="small" color="dark"></ion-icon></ion-chip>
      </div>
    </div>
  </ion-item>
  <div class="image-wrapper" *ngIf="photoInLocalStorage">
    <img class="added-image" [src]="src" />
    <ion-chip color="danger" (click)="removePhoto()"><ion-icon name="close" size="small" color="danger"></ion-icon></ion-chip>
  </div>

  <ion-note class="when-happened">When did this happen?</ion-note>
  <ion-item>
    <ion-datetime-button color="dark" datetime="datetime"></ion-datetime-button>
    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime 
        id="datetime"
        presentation="date"
        ></ion-datetime>
      </ng-template>
    </ion-modal>
  </ion-item>

  <ion-item>
    <ion-note class="recurring">Make this a recurring post every year on this date?</ion-note><ion-toggle size="small" color="dark"></ion-toggle>
  </ion-item>
</ion-content>

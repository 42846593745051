import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import write_blob from 'capacitor-blob-writer';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Storage } from '@aws-amplify/storage';
import { APIService } from 'src/app/API.service';


const APP_DIRECTORY = Directory.Documents;

@Component({
  selector: 'app-add-hub-post-modal',
  templateUrl: './add-hub-post-modal.page.html',
  styleUrls: ['./add-hub-post-modal.page.scss'],
})
export class AddHubPostModalPage implements OnInit {

  @ViewChild('photopicker') picker: ElementRef;

  selected: any;
  currentFolder = 'hub-photo';
  photoInLocalStorage: boolean;
  validEntry: boolean;
  src;
  blob;
  path;

  postText;

  constructor(
    private modalController: ModalController,
    private loadingController: LoadingController,
    private sanitizer: DomSanitizer,
    private api: APIService
  ) { }

  ngOnInit() {
    this.validEntry = false;
  }

  // functions to add media to timeline
  async fileSelected(event) {

    this.selected = event.target.files[0];

    const loading = await this.loadingController.create({
      spinner: 'lines-sharp-small',
      translucent: false,
      cssClass: 'spinner-loading'
    });

    loading.present();

    this.path = `${this.currentFolder}/${this.selected.name}`;

    await write_blob({
      directory: APP_DIRECTORY,
      path: `${this.currentFolder}/${this.selected.name}`,
      blob: this.selected,
      recursive: true,
      on_fallback(error) {
        console.log('error: ', error)
      }
    })

    loading.dismiss();
    this.savedToLocalStorage();
  }

  addFile() {
    this.picker.nativeElement.click();
  }

  async savedToLocalStorage() {
    const status = { status: true }
    if (!status['status']) {
      this.photoInLocalStorage = false;
    } else {
      this.src = await this.loadMediaFromStorage().then(data => data)
      this.photoInLocalStorage = true;
    }
  }

  removePhoto(){
    this.photoInLocalStorage = false;
  }

  async loadMediaFromStorage() {
    const file = await Filesystem.readFile({
      directory: APP_DIRECTORY,
      path: this.path
    })
    this.blob = this.b64toBlob(file.data)
    if (Capacitor.getPlatform() === "web") {
      let blobUrl = URL.createObjectURL(this.blob) as any;
      blobUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl)
      this.src = blobUrl;
      console.log(this.src)
      return this.src;
    } else {
      const file_uri = await Filesystem.getUri({
        path: this.path,
        directory: APP_DIRECTORY
      })
      this.src = this.sanitizer.bypassSecurityTrustUrl(Capacitor.convertFileSrc(file_uri.uri));
      return this.src;
    }
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  async submitToS3(filename, blob) {
    return await Storage.put(filename, blob, { contentType: "image/jpeg"})
  }

  async userTyped(event){
    if(event.detail.value.length > 0){
      this.validEntry = true;
      this.postText = event.detail.value
    } else {
      this.validEntry = false;
    }
  }


  async submitPost() {
    const loading = await this.loadingController.create({
      spinner: 'lines-sharp-small',
      translucent: false,
      cssClass: 'spinner-loading'
    });

    loading.present();

    let filename_ext;
    let filename;
    if(this.selected){
      let extension = this.selected.name.split('.').pop();
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let hour = date.getHours();
      let mins = date.getMinutes();
      let secs = date.getSeconds();
      filename_ext = `hubphoto_upload_${month}_${day}_${year}_${hour}_${mins}_${secs}` + `.${extension}`
      filename = filename_ext.split('.').slice(0, -1).join('.')
    }
    let value = Math.floor(Math.random() * 10000)
    let hubId = value.toString().concat("-hubpost")
    let now = new Date().toISOString()
    let photoPost;
    let uploadToS3;
    if(this.blob){
      photoPost = true;
      await this.submitToS3('hubpost-photo/' + filename_ext, this.blob);
    } else {
      photoPost = false;
      filename_ext = null;
      uploadToS3 = new Promise(resolve => resolve('no image added'));
    }

    let profilePicSrc = await this.api.GetProfilePictureProfileID(localStorage.getItem('profileID'))
    let username = await this.api.GetUsernameFromProfileId(localStorage.getItem('profileID'))
    await this.api.CreateHubPosts(
      {
        id: hubId, 
        sortKey: "hubpost", 
        postType: "milestone", 
        timePosted: now,
        postDetails: `{
          "postText": ${JSON.stringify(this.postText)},
          "postImagePresent": ${JSON.stringify(photoPost)},
          "src": ${JSON.stringify(filename_ext)},
          "profilePic": ${JSON.stringify(profilePicSrc)},
          "username": ${JSON.stringify(username)}
        }`,
      })

    loading.dismiss();
    await this.closeModal();
  }

  async closeModal() {
    this.modalController.dismiss({
      'dismissed': true,
      'formStatus': 'markAsUntouched',
      'noInput': true
    });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReloadTimelineService {
  
  public refreshClicked: boolean;
  refreshTimeline: Subject<boolean> = new Subject<boolean>();

  public refreshHubClicked: boolean;
  refreshHub: Subject<boolean> = new Subject<boolean>();

  public activeVideo: any;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.refreshTimeline.subscribe((value) => {
      return this.refreshClicked = value;
    })
    this.refreshHub.subscribe((value) => {
      return this.refreshHubClicked = value;
    })
   }

  toggleRefreshTimeline() {
    let route = this.activatedRoute.snapshot['_routerState'].url;
    if (route === '/timeline'){
      this.refreshClicked = true;
      this.refreshTimeline.next(this.refreshClicked)
      setTimeout(() => {
        this.refreshTimeline.next(!this.refreshClicked)
      }, 500)
    } else {
      this.refreshTimeline.next(!this.refreshClicked)
    }
  }

  toggleRefreshHub() {
    let route = this.activatedRoute.snapshot['_routerState'].url;
    if (route === '/message-board') {
      this.refreshClicked = true;
      this.refreshHub.next(this.refreshHubClicked)
      setTimeout(() => {
        this.refreshHub.next(!this.refreshHubClicked)
      }, 100)
    } else {
      this.refreshHub.next(!this.refreshHubClicked)
    }
  }

  findActiveVideo(video){
    this.activeVideo = video;
  }
}  


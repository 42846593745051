import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'oriolesFilter'
})
export class OriolesFilterPipe implements PipeTransform {

  transform(value): any {
    let orioles: any;
    value.map(team => {
      if(team.team === 'Baltimore Orioles'){
        orioles = team;
      }
    })
    console.log(orioles)
    return [orioles];
  }

}

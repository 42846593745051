<ion-header>
  <ion-toolbar mode="ios">
    <ion-title>Why are we using this app?</ion-title>
    <ion-note color="tertiary" slot="start" (click)="backToWall()" class="cancel">&nbsp;&nbsp;<ion-icon
        name="chevron-back-outline"></ion-icon>Back</ion-note>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-note>Facebook and Instagram data use information...</ion-note>

</ion-content>


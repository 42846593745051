<ion-header mode="md" class="ion-no-border">
  <ion-toolbar color="light">
      <ion-title class="username-toolbar">
        <strong>{{profileData.username}}</strong>
      </ion-title>
      <ion-icon (click)="openMenu()" class="menu-button" slot="end" name="menu"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="profile-info">
    <div *ngIf="!editAccess" class="top-of-profile-edit">
      <div class="left-top-profile">
        <ion-avatar *ngIf="!profilePhoto">
          <ion-img src="../../../assets/avatar.svg"></ion-img>
        </ion-avatar>
        <ion-avatar *ngIf="profilePhoto">
          <ion-img [src]="profilePhoto"></ion-img>
        </ion-avatar>
        <div class="name-info">
          <p class="fullname" *ngIf="profileData.first_name && profileData.last_name">
            {{profileData.first_name}}&nbsp;{{profileData.last_name}}</p>
          <p class="fullname" *ngIf="!profileData.first_name || !profileData.last_name">{{profileData.username}}</p>
        </div>
        <div class="relationship-div">
          <ion-icon *ngIf="admin" src="../../../assets/streamline/star-1.svg" class="star"></ion-icon>
          <p class="relation">{{profileData.relation}}</p>
        </div>
      </div>
      <div class="right-top-profile-wrapper">
        <div class="birthday-div">
          <ion-icon class="birthday-icon" src="../../../assets/streamline/birthday-cake-1.svg"></ion-icon>&nbsp;&nbsp;
          <p class="birthday"><strong>{{profileData.birthday | date: 'MMMM d'}}{{(profileData.birthday | date: 'd') |
              dateSuffixPipe}}</strong></p>
          <div>
          </div>
        </div>
        <div class="right-top-profile">
          <div class="stats-div">
            <p class="stats-count"><strong>{{photosPostedCount}}</strong></p>
            <div>
              <p class="stats-text">photo posts</p>
            </div>
          </div>
          <div class="stats-div">
            <p class="stats-count"><strong>{{videosPostedCount}}</strong></p>
            <div>
              <p class="stats-text">video posts</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="editAccess" class="top-of-profile-edit">
      <div class="left-top-profile">
        <ion-avatar *ngIf="!profilePhoto">
          <img src="../../../assets/avatar.svg">
        </ion-avatar>
        <ion-avatar *ngIf="profilePhoto">
          <img [src]="profilePhoto">
        </ion-avatar>
        <div class="name-info">
          <p class="fullname" *ngIf="profileData.first_name && profileData.last_name">{{profileData.first_name}}&nbsp;{{profileData.last_name}}</p>
          <p class="fullname" *ngIf="!profileData.first_name || !profileData.last_name">{{profileData.username}}</p>
        </div>
        <div class="relationship-div">
          <ion-icon *ngIf="admin" src="../../../assets/streamline/star-1.svg" class="star"></ion-icon>
          <p class="relation">{{profileData.relation}}</p>
        </div>
      </div>
      <div class="right-top-profile-wrapper">
        <div class="birthday-div">
          <div><ion-icon class="birthday-icon" src="../../../assets/streamline/birthday-cake-1.svg"></ion-icon></div>&nbsp;&nbsp;
          <p class="birthday"><strong class="edit-birthday">{{profileData.birthday | date: 'MMMM d'}}{{(profileData.birthday | date: 'd') | dateSuffixPipe}}</strong></p>
        </div>
        <div class="right-top-profile">
          <div class="stats-div">
            <p class="stats-count"><strong>{{photosPostedCount}}</strong></p>
            <div><p class="stats-text">photo posts</p></div>
          </div>
          <div class="stats-div">
            <p class="stats-count"><strong>{{videosPostedCount}}</strong></p>
            <div><p class="stats-text">video posts</p></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bio">
      <p class="bio-text">{{profileData.bio}}</p>
    </div>
    <ion-chip *ngIf="editAccess" color="medium" class="edit-profile-button" expand="full" (click)="updateProfile()">Edit Profile</ion-chip>
  
    <ion-toolbar class="separator-toolbar">
    <ion-segment [(ngModel)]="select_buttons" (ionChange)="buttonClicked($event)">
      <ion-segment-button value="photo-uploads" checked>
        <ion-icon name="camera-outline"></ion-icon>
      </ion-segment-button>
      <ion-segment-button value="video-uploads">
        <ion-icon name="videocam-outline"></ion-icon>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
    <div class="no-photos" *ngIf="noPhotosYet && photosClicked">
      <h5 color="light">No photos posted yet.</h5>
    </div>
    <ion-grid class="postedphotos" *ngIf="!noPhotosYet && photosClicked">
      <ion-row class="postedphotos_row">
        <ion-col *ngFor="let image of profileImageData" size-lg="4" size-md="4" size-sm="4" size-xs="4">
            <div (click)="openClickModal(image.id)" class="square" *ngIf="!image.deleted && loaded" style="background-image: url({{image.base64}}); background-color: var(--ion-color-light)">
            </div>
            <ion-skeleton-text *ngIf="!loaded" animated style="width: 99.8%; margin: 0; background-position: center; object-fit: contain; aspect-ratio: 1;"></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="no-videos" *ngIf="noVideosYet && videosClicked">
      <h5 color="light">No videos posted yet.</h5>
    </div>
    <ion-grid class="postedvideos" *ngIf="!noVideosYet && videosClicked">
      <ion-row class="postedvideos_row">
        <ion-col *ngFor="let video of profileMediaData" size-lg="4" size-md="4" size-sm="4" size-xs="4">
          <div (click)="openClickModal(video.id)" id="video-wrapper" class="square" *ngIf="!video.deleted" style="background-image: url({{video.posterImage}}); background-color: var(--ion-color-light)">
            <div id="play-controls">
              <ion-icon name="play-outline" large></ion-icon>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>



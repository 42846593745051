<ion-header>
  <ion-toolbar>
    <div class="top-pre-post-game" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'Final' || baseballData.gameInfo.initialGameData.status.detailedState === 'Game Over' || baseballData.gameInfo.gameStarted === 'false'">
      <div class="scoreboard" slot="start">
        <div class="away-info">
          <ion-avatar>
            <img class="scoreboard-logo-away" alt="team-logo-away" src="https://midfield.mlbstatic.com/v1/team/{{baseballData.gameInfo.initialGameData.teams.away.id}}/spots/120" />
          </ion-avatar>
          <ion-note class="score" color="dark">{{baseballData.gameInfo.currentPlay.result.awayScore}}</ion-note>
        </div>

        <div class="date-status" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'Final' || baseballData.gameInfo.initialGameData.status.detailedState === 'Game Over' || baseballData.gameInfo.gameStarted === 'false'">
          <ion-note *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'Final' || baseballData.gameInfo.initialGameData.status.detailedState === 'Game Over'" class="final" color="dark">{{baseballData.startTime | date: 'E M/d' }} <br> Final</ion-note>
          <ion-note *ngIf="baseballData.gameInfo.gameStarted === 'false'" class="final" color="dark">{{baseballData.startTime | date: 'E M/d' }} <br> {{baseballData.startTime | date: 'h:mm a'}} ET</ion-note>
        </div>

        <div class="home-info">
          <ion-note class="score" color="dark">{{baseballData.gameInfo.currentPlay.result.homeScore}}</ion-note>
          <ion-avatar>
            <img class="scoreboard-logo-home" alt="team-logo-away" src="https://midfield.mlbstatic.com/v1/team/{{baseballData.gameInfo.initialGameData.teams.home.id}}/spots/120" />
          </ion-avatar>
        </div>
      </div>
      <div class="exit">
        <ion-icon slot="end" (click)="backToHub()" name="close-outline"></ion-icon>
      </div>
    </div>

    <!-- <div class="top-live-game" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState !== 'Final' || baseballData.gameInfo.initialGameData.status.detailedState !== 'Game Over' && baseballData.gameInfo.gameStarted === 'true'">
      <div class="scoreboard">
        <div class="away-info">
          <ion-avatar>
            <img class="scoreboard-logo-away" alt="team-logo-away" src="https://midfield.mlbstatic.com/v1/team/{{baseballData.gameInfo.initialGameData.teams.away.id}}/spots/120" />
          </ion-avatar>
          <ion-note class="score" color="dark">{{baseballData.gameInfo.currentPlay.result.awayScore}}</ion-note>
        </div>
  
        <div *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'" class="bases-outs-inprogress">
          <ion-note class="half-inning" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">{{baseballData.gameInfo.currentHalfInning}}</ion-note>
          <ion-avatar *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">
            <img *ngIf="!baseballData.gameInfo.currentPlay.matchup.postOnFirst && !baseballData.gameInfo.currentPlay.matchup.postOnSecond && !baseballData.gameInfo.currentPlay.matchup.postOnThird" src="../../../assets/baseball-data/bases-clear.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.matchup.postOnFirst && (!baseballData.gameInfo.currentPlay.matchup.postOnSecond && !baseballData.gameInfo.currentPlay.matchup.postOnThird)" src="../../../assets/baseball-data/runner-on-first-only.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.matchup.postOnSecond && (!baseballData.gameInfo.currentPlay.matchup.postOnFirst && !baseballData.gameInfo.currentPlay.matchup.postOnThird)" src="../../../assets/baseball-data/runner-second-only.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.matchup.postOnThird && (!baseballData.gameInfo.currentPlay.matchup.postOnFirst && !baseballData.gameInfo.currentPlay.matchup.postOnSecond)" src="../../../assets/baseball-data/runner-third-only.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.matchup.postOnFirst && baseballData.gameInfo.currentPlay.matchup.postOnSecond && !baseballData.gameInfo.currentPlay.matchup.postOnThird" src="../../../assets/baseball-data/runners-first-second.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.matchup.postOnSecond && baseballData.gameInfo.currentPlay.matchup.postOnThird && !baseballData.gameInfo.currentPlay.matchup.postOnFirst" src="../../../assets/baseball-data/runners-second-third.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.matchup.postOnFirst && baseballData.gameInfo.currentPlay.matchup.postOnSecond && baseballData.gameInfo.currentPlay.matchup.postOnThird" src="../../../assets/baseball-data/bases-loaded.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.matchup.postOnFirst && baseballData.gameInfo.currentPlay.matchup.postOnThird && !baseballData.gameInfo.currentPlay.matchup.postOnSecond" src="../../../assets/baseball-data/runners-corners.svg" />
          </ion-avatar>
          <ion-note *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'" class="current-count">{{baseballData.gameInfo.currentPlay.count.balls}} - {{baseballData.gameInfo.currentPlay.count.strikes}}</ion-note>
          <ion-avatar class="number-of-outs" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">
            <img *ngIf="baseballData.gameInfo.currentPlay.count.outs === 0" src="../../../assets/baseball-data/no-outs.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.count.outs === 1" src="../../../assets/baseball-data/one-out.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.count.outs === 2" src="../../../assets/baseball-data/two-outs.svg" />
            <img *ngIf="baseballData.gameInfo.currentPlay.count.outs === 3" src="../../../assets/baseball-data/three-outs.svg" />
          </ion-avatar>
        </div>

        <div class="home-info">
          <ion-note class="score" color="dark">{{baseballData.gameInfo.currentPlay.result.homeScore}}</ion-note>
          <ion-avatar>
            <img class="scoreboard-logo-home" alt="team-logo-away" src="https://midfield.mlbstatic.com/v1/team/{{baseballData.gameInfo.initialGameData.teams.home.id}}/spots/120" />
          </ion-avatar>
        </div>

      </div>

      <div class="duel" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState !== 'Final' || baseballData.gameInfo.initialGameData.status.detailedState !== 'Game Over' && baseballData.gameInfo.gameStarted === 'true'">
        <div class="current-batter">
          <ion-card-subtitle>At Bat</ion-card-subtitle>
          <div class="batter-stats">
            <ion-avatar class="headshot-live"><img src="https://midfield.mlbstatic.com/v1/people/{{baseballData.gameInfo.currentPlay.matchup.batter.id}}/spots/120" /></ion-avatar>
            <ion-note class="current-batter-stats" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">{{baseballData.gameInfo.currentPlay.matchup.batter.fullName}}</ion-note>
            <ion-note class="current-batter-stats" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress' && baseballData.gameInfo.currentBatterSplits.stats">{{baseballData.gameInfo.currentPlay.matchup.batter.fullName}}&nbsp;({{baseballData.gameInfo.currentBatterSplits.stats}})</ion-note>
          </div>
        </div>
        <div class="current-pitcher">
          <ion-card-subtitle>Pitching</ion-card-subtitle>
          <div class="pitcher-stats">
            <ion-avatar class="headshot-live"><img src="https://midfield.mlbstatic.com/v1/people/{{baseballData.gameInfo.currentPlay.matchup.pitcher.id}}/spots/120" /></ion-avatar>
            <ion-note class="current-pitcher-stats" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">{{baseballData.gameInfo.currentPlay.matchup.pitcher.fullName}}<br>{{baseballData.gameInfo.currentPitcherStats}}</ion-note>
          </div>
        </div>
      </div>

      <div class="exit">
        <ion-icon slot="end" (click)="backToHub()" name="close-outline"></ion-icon>
      </div>
    </div> -->
      <!-- <ion-icon *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress' && !accordionOpen" class="see-more" name="chevron-down" (click)="openAccordion()"></ion-icon>
      <ion-icon *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress' && accordionOpen" class="see-more" name="chevron-up" (click)="openAccordion()"></ion-icon>
      <ion-accordion value="at-bat" id="accordion" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">
        <div class="bottom" slot="content">
            <div class="overall-matchup-data">
              <div class="batter">
                <ion-card-subtitle>At Bat</ion-card-subtitle>
                <div class="batter-stats">
                  <ion-avatar class="headshot-live"><img src="https://midfield.mlbstatic.com/v1/people/{{baseballData.gameInfo.currentPlay.matchup.batter.id}}/spots/120" /></ion-avatar>
                  <ion-note class="current-batter-stats" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">{{baseballData.gameInfo.currentPlay.matchup.batter.fullName}}<br>{{baseballData.currentBatterStats}}</ion-note>
                </div>
              </div>
              <div class="pitcher">
                <ion-card-subtitle>Pitching</ion-card-subtitle>
                <div class="pitcher-stats">
                  <ion-note class="current-pitcher-stats" *ngIf="baseballData.gameInfo.initialGameData.status.detailedState === 'In Progress'">{{baseballData.gameInfo.currentPlay.matchup.pitcher.fullName}}<br>{{baseballData.currentPitcherStats}}</ion-note>
                  <ion-avatar class="headshot-live"><img src="https://midfield.mlbstatic.com/v1/people/{{baseballData.gameInfo.currentPlay.matchup.pitcher.id}}/spots/120" /></ion-avatar>
                </div>
              </div>
            </div>
            <div class="plays">
              <div class= "last-update" *ngFor="let pitch of baseballData.gameInfo.currentPlay.playEvents">
                <ion-chip color="success" [outline]="true" *ngIf="pitch.isPitch && pitch.details.call.code === 'B'">{{pitch.pitchNumber}}</ion-chip>&nbsp;<ion-note *ngIf="pitch.isPitch && pitch.details.call.code === 'B'">{{pitch.pitchData.endSpeed}} MPH {{pitch.details.type.description}} -- {{pitch.details.description}}</ion-note>
                <ion-chip color="danger" [outline]="true" *ngIf="pitch.isPitch && (pitch.details.call.code === 'S' || pitch.details.call.code === 'F' || pitch.details.call.code === 'C')">{{pitch.pitchNumber}}</ion-chip>&nbsp;<ion-note *ngIf="pitch.isPitch && (pitch.details.call.code === 'S' || pitch.details.call.code === 'F' || pitch.details.call.code === 'C')">{{pitch.pitchData.endSpeed}} MPH {{pitch.details.type.description}} -- {{pitch.details.description}}</ion-note>
                <ion-chip color="primary" [outline]="true" *ngIf="pitch.isPitch && pitch.details.call.code === 'X'">{{pitch.pitchNumber}}</ion-chip>&nbsp;<ion-note *ngIf="pitch.isPitch && pitch.details.call.code === 'X'">{{pitch.pitchData.endSpeed}} MPH {{pitch.details.type.description}} -- {{pitch.details.description}}</ion-note>
              </div>
            </div>
        </div>
      </ion-accordion> -->
  </ion-toolbar>
  <!-- <ion-toolbar>
    <ion-note *ngIf="newPlaysAnimate" [@playRender]="'in'">{{newPlaysAnimate.resultDescription}}</ion-note>
  </ion-toolbar> -->
</ion-header>

<ion-content>
  <ion-infinite-scroll threshold="0%" (ionInfinite)="loadData($event)" position="top">
    <ion-infinite-scroll-content *ngIf="newChatsSpinner" loadingSpinner="lines-sharp-small">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  <div class="loading-centered" *ngIf="spinner">
    <ion-spinner name="lines-sharp-small"></ion-spinner>
  </div>

  <div *ngFor="let chat of chats; let i=index" class="chat-wrapper">
    <div class="time-posted-wrapper">
        <ion-note class="time-posted-note" *ngIf="i % 5 == 1">{{chat.timePosted | date: 'EEE, MMM d'}}{{(chat.timePosted | date: 'd') | dateSuffixPipe}} at {{chat.timePosted | date: 'h:mma'}}</ion-note>
        <div class="chat-wrapper-user" *ngIf="chat.username.id === this.userUsernameID">
          <ion-card class="chat-bubble-user"  lines="none" color="tertiary">
            <ion-note color="light">{{chat.chat}} </ion-note>
          </ion-card>
          <ion-avatar>
            <img *ngIf="!chat.profile.profilepicture.imageurl" src="../../../assets/avatar.svg" />
            <img *ngIf="chat.profile.profilepicture.imageurl"
              src="https://ik.imagekit.io/bkf4g8lrl/profile-photos/{{chat.profile.profilepicture.imageurl}}" />
          </ion-avatar>
        </div>
        <div class="chat-wrapper-otheruser" *ngIf="chat.username.id !== this.userUsernameID">
          <ion-avatar>
            <img *ngIf="!chat.profile.profilepicture.imageurl" src="../../../assets/avatar.svg" />
            <img *ngIf="chat.profile.profilepicture.imageurl"
              src="https://ik.imagekit.io/bkf4g8lrl/profile-photos/{{chat.profile.profilepicture.imageurl}}" />
          </ion-avatar>
          <ion-card class="chat-bubble-otheruser" lines="none" color="light">
            <ion-note color="dark">{{chat.chat}} </ion-note>
          </ion-card>
        </div>
      </div>
    </div>
  <div id="empty-div" class="empty-div"></div>
</ion-content>

<ion-footer lines="none">
  <form [formGroup]="postChat" (ngSubmit)="createChat(postChat.value)">
    <ion-item class="chat-box" lines="none">
      <ion-avatar class="chat-user-pic">
        <img *ngIf="!hasProfilePic" id="avatar" src="../../../assets/avatar.svg">
        <img *ngIf="hasProfilePic" id="avatar" [src]="profilePicture" />
      </ion-avatar>
      <ion-textarea (ionFocus)="userTouchedEvent($event)" (ionChange)="userTypedEvent($event)" shape="round" placeholder="How 'bout dem O's..." lines="none" formControlName="chat" rows="1" minLength="300" autoGrow="true" class="chat-text-area">
        <ion-icon (click)="createChat(postChat.value)" *ngIf="userTyped" color="tertiary" size="large" name="arrow-up-circle"></ion-icon>
      </ion-textarea>
    </ion-item>
  </form>
</ion-footer>
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { APIService, ModelSortDirection } from 'src/app/API.service';
import { MediaService } from 'src/app/services/media.service';
import { ModalController, IonTextarea, ToastController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-hub-comment-modal',
  templateUrl: './hub-comment-modal.page.html',
  styleUrls: ['./hub-comment-modal.page.scss'],
})
export class HubCommentModalPage implements OnInit {

  @ViewChild('editCommentInput') editCommentInput: IonTextarea;

  currentUserUsername;
  profilePictureUrl: string;
  currentUserProfilePicture: string;
  hubPostID;
  noInput;
  editComment;
  public commentArray: any = [];
  public comments: any = [];
  public postDetails: any = [];
  userNameID: any;
  spinner: boolean;
  nextToken;
  newCommentsSpinner;

  postCommentForm = {} as FormGroup;
  writeCommentForm = {} as FormGroup;
  updateCommentForm = {} as FormGroup;
  replyCommentForm = {} as FormGroup;

  onCreateHubCommentsSubscription: Subscription | null = null;
  onUpdateHubCommentsSubscription: Subscription | null = null;
  onDeleteHubCommentsSubscription: Subscription | null = null;

  constructor(
    private api: APIService,
    private modalController: ModalController,
    private platform: Platform,
    private toastController: ToastController
  ) {
    this.updateCommentForm = new FormGroup({
      comment: new FormControl('')
    }),
    this.writeCommentForm = new FormGroup({
      comment: new FormControl('', Validators.required)
    }),
    this.replyCommentForm = new FormGroup({
      reply: new FormControl('', Validators.required)
    })
   }

  get f() {
    return this.postCommentForm.controls;
  }

  async ngOnInit() {
    this.spinner = true;
    let currentUser = await this.api.GetProfile(localStorage.getItem('profileID'))
    this.currentUserUsername = localStorage.getItem('username');
    this.currentUserProfilePicture = "https://ik.imagekit.io/bkf4g8lrl/profile-photos/" + currentUser.profilepicture.imageurl;
    let postDetails = await this.api.GetHubPosts(this.hubPostID);
    this.userNameID = localStorage.getItem('usernameID')

    if(postDetails.sportsGameID === null && postDetails.postType === 'sport'){
      const updatedPostDetails = JSON.parse(postDetails.postDetails)
      const updatedDescription = `The Baltimore Orioles (${updatedPostDetails.wins}-${updatedPostDetails.losses}) are currently ${updatedPostDetails.standingsPrefix} in the AL East and ${updatedPostDetails.divisionGamesBack} games back from first place in the division.`
      this.postDetails = {
        id: postDetails.id,
        description: updatedDescription,
        time_posted: postDetails.timePosted,
        profilePicture: "https://midfield.mlbstatic.com/v1/team/110/spots/120"
      }
    }

    let comments = await this.api.HubCommentsBySorterValueAndTime_posted(this.hubPostID + "-comment", null, ModelSortDirection.DESC, null, 20).then((data) => data)
    this.comments = comments.items;
    this.nextToken = comments.nextToken;
    this.spinner = false;
    // let profilePictureData = await this.api.GetProfilePictureProfileID(postDetails.profile.id)


    this.onCreateHubCommentsSubscription = <Subscription>(
      this.api.OnCreateHubCommentsListener().subscribe({
        next: async (event: any) => {
          let comments = await this.api.HubCommentsBySorterValueAndTime_posted(this.hubPostID + "-comment", null, ModelSortDirection.DESC).then((data) => data)
          this.comments = comments.items;
        }
      })
    )

    this.onUpdateHubCommentsSubscription = <Subscription>(
      this.api.OnUpdateHubCommentsListener().subscribe({
        next: async (event: any) => {
          let comments = await this.api.HubCommentsBySorterValueAndTime_posted(this.hubPostID + "-comment", null, ModelSortDirection.DESC).then((data) => data)
          this.comments = comments.items;
        }
      })
    )

    this.onDeleteHubCommentsSubscription = <Subscription>(
      this.api.OnDeleteHubCommentsListener().subscribe({
        next: async (event: any) => {
          let comments = await this.api.HubCommentsBySorterValueAndTime_posted(this.hubPostID + "-comment", null, ModelSortDirection.DESC).then((data) => data)
          this.comments = comments.items;
        }
      })
    )
    // if (profilePictureData) {
    //   this.profilePictureUrl = "https://ik.imagekit.io/bkf4g8lrl/profile-photos/" + profilePictureData.imageurl;
    // } else {
    //   this.profilePictureUrl = "../../../assets/avatar.svg";
    // }
  }

  async ngOnDestroy() {
    if (this.onCreateHubCommentsSubscription) {
      await this.onCreateHubCommentsSubscription.unsubscribe();
    }
    if (this.onUpdateHubCommentsSubscription) {
      await this.onUpdateHubCommentsSubscription.unsubscribe();
    }
    if (this.onDeleteHubCommentsSubscription) {
      await this.onDeleteHubCommentsSubscription.unsubscribe();
    }
    this.platform.pause.subscribe(async () => {
      console.log('pausing subscription')
    });
  }

  async postWrittenComment(imagepost) {
    let userComment = imagepost.comment;
    let time_posted = new Date().toISOString()

    try {
      await this.api.CreateHubComments({ "usernameID": localStorage.getItem("usernameID"), "profileID": localStorage.getItem("profileID"), "comment": userComment, "time_posted": time_posted, "HubPostsID": this.hubPostID, "sorterValue": this.hubPostID + "-comment" }).then(() => console.log('success'))
      this.writeCommentForm.reset();
    } catch (error) {
      this.failureCallback(error)
    }
  }

  async updateComment(id, editSlider, comment) {
    localStorage.setItem('commentID', id)
    editSlider.close();
    this.updateCommentForm.controls['comment'].setValue(comment);

    if (this.platform.is('hybrid')) {
      setTimeout(() => {
        this.editCommentInput.setFocus();
      }, 500)
    }

    this.noInput = false;
    this.editComment = true;
  }

  async postUpdatedComment(value) {
    await this.api.UpdateHubComments({ id: localStorage.getItem('commentID'), comment: value.comment })
    localStorage.removeItem('commentID')
    this.noInput = false;
    this.editComment = false;
  }

  async deleteComment(id) {
    await this.api.DeleteHubComments({ id: id })
    this.presentDeleteComment();
  }

  async presentDeleteComment() {
    const toast = await this.toastController.create({
      message: 'Your comment has been deleted.',
      position: 'top',
      duration: 2000,
      keyboardClose: true,
      color: 'success'
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  failureCallback(error) {
    console.error("Error generating comment: " + error);
  }


  async loadData(event) {
    if (this.nextToken !== null) {
      this.newCommentsSpinner = true;
      this.api.HubCommentsBySorterValueAndTime_posted(this.hubPostID + "-comment", null, ModelSortDirection.DESC, null, 20, this.nextToken).then((data) => {
        let dataPull = data.items;
        this.nextToken = data.nextToken;

        Object.entries(dataPull).forEach(([key, value]) => { this.comments[this.comments.length] = value })
        event.target.complete();
      })
    } else {
      this.newCommentsSpinner = false;
      event.target.complete();
    }
  } 
  
  


  async closeModal() {
    this.modalController.dismiss({
      'dismissed': true,
      'formStatus': 'markAsUntouched',
      'noInput': true
    });
  }

}

import { Component, ViewChild } from '@angular/core';
import { APIService, ModelSortDirection } from 'src/app/API.service';
import { SportsService } from 'src/app/services/sports.service';
import { BehaviorSubject, Observable, Subject, Subscription, finalize } from 'rxjs';
import { Amplify, Hub } from 'aws-amplify';
import { CONNECTION_STATE_CHANGE, ConnectionState } from '@aws-amplify/pubsub';
import awsconfig from './../../../aws-exports';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { ModalController, Platform, AlertController, ViewDidLeave, ViewDidEnter, IonContent, ActionSheetController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseballChatroomPage } from 'src/app/modals/baseball-chatroom/baseball-chatroom.page';
import { HubCommentModalPage } from 'src/app/modals/hub-comment-modal/hub-comment-modal.page';
import { ReloadTimelineService } from 'src/app/services/reload-timeline.service';
import { AddHubPostModalPage } from 'src/app/modals/add-hub-post-modal/add-hub-post-modal.page';
import { Storage } from '@aws-amplify/storage';

@Component({
  selector: 'app-message-board',
  templateUrl: 'message-board.page.html',
  styleUrls: ['message-board.page.scss']
})
export class MessageBoardPage implements ViewDidEnter, ViewDidLeave {
  @ViewChild(IonContent) ionContent: IonContent;

  onUpdateSportsGame: Subscription | null = null;
  onUpdateHubPost: Subscription | null = null;
  onDeleteHubPost: Subscription | null = null;
  onCreateHubLikesSubscription: Subscription | null = null;
  onDeleteHubLikesSubscription: Subscription | null = null;
  onCreateHubCommentsSubscription: Subscription | null = null;
  onDeleteHubCommentsSubscription: Subscription | null = null;
  refreshHubAction: Subscription | null = null;
  
  data: any;
  hubData: any = [];
  oriolesData: any = [];
  liveData: any = [];
  currentData: any = [];
  lastEvent: any = [];
  gamePlays: any = [];
  lastEventDescription;
  opponentName;
  currentHalfInning;
  mobilePlatform;
  pitcherInfo;
  weatherInfo;
  nextToken;
  scrollFinished;

  priorConnectionState: ConnectionState;

  currentUserUsernameID;
  currentUserProfileID;
  hubRefresh;
  refresh;

  constructor(
    private api: APIService,
    private sportsService: SportsService,
    private reloadTimelineService: ReloadTimelineService,
    private platform: Platform,
    private alertController: AlertController,
    private router: Router,
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private toastController: ToastController
  ) {
    this.mobilePlatform = this.platform.is("mobile");
  }

  async ionViewDidEnter() {
    console.log(this.router.url)
    this.currentUserUsernameID = localStorage.getItem('usernameID');
    this.currentUserProfileID = localStorage.getItem('profileID');
    this.startSubscriptions();
    await this.getHubData();
  }

  async scrollToTop(){
    this.refresh = true;
    if (this.platform.is('hybrid')) {
      await Haptics.impact({ style: ImpactStyle.Heavy })
    }
    this.ionContent.scrollToTop(100).then(async () => {
      await this.getHubData();
      this.scrollFinished = false;
      this.refresh = false;
    })
  }


  async openBaseballChatroom(gameData, gameID){
    const modal = await this.modalController.create({
      component: BaseballChatroomPage,
      componentProps: {
        baseballData: gameData,
        sportsGameID: gameID
      }
    });
    return await modal.present();
  }

  async openPostModal(){
    const modal = await this.modalController.create({
      component: AddHubPostModalPage,
    })
    return await modal.present();
  }

  async openHubCommentModal(id){
    const modal = await this.modalController.create({
      component: HubCommentModalPage,
      componentProps: {
        hubPostID: id
      }
    })
    return await modal.present();
  }

  startSubscriptions() {
    this.refreshHubAction = <Subscription>(
      this.reloadTimelineService.refreshHub.subscribe(async value => {
        this.hubRefresh = value;
        if (this.hubRefresh) {
          await this.scrollToTop();
        }
      })
    )
    this.onDeleteHubPost = <Subscription>(
      this.api.OnDeleteHubPostsListener().subscribe({
        next: async (event: any) => {
          const data = event;
        },
        error: async (err: any) => {
          console.log(err)
        },
        complete: () => console.log('hub subscription complete')
      })
    )
    this.onUpdateHubPost = <Subscription>(
      this.api.OnUpdateHubPostsListener().subscribe({
        next: async (event: any) => {
          const data = event;
          console.log(data)
        },
        error: async (err: any) => {
          console.log(err)
        },
        complete: () => console.log('hub subscription complete')
      })
    )
    this.onUpdateSportsGame = <Subscription>(
      this.api.OnUpdateSportsGameListener().subscribe({
        next: async (event: any) => {
          const data = event;
          this.hubData.map(async (game) => {
            if(game.sportsgame !== null){
              if(data.value.data.onUpdateSportsGame.id === game.sportsgame.id){
                game.sportsgame.gameInfo.currentHalfInning = null;
                game.sportsgame.gameInfo = JSON.parse(data.value.data.onUpdateSportsGame.gameInfo)
                game.sportsgame.gameInfo.currentHalfInning = game.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' ? game.sportsgame.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(game.sportsgame.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", game.sportsgame.gameInfo.currentPlay.about.inning.toString()) : null;
                game.sportsgame.lastUpdate = data.value.data.onUpdateSportsGame.lastUpdate
  
                if (Date.parse(game.sportsgame.startTime) > Date.now()) {
                  game.sportsgame.gameInfo.gameStarted = false;
                } else {
                  game.sportsgame.gameInfo.gameStarted = true;
                }
  
                if (game.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress') {
                  game.sportsgame.gameInfo.currentHalfInning = game.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' ? game.sportsgame.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(game.sportsgame.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", game.sportsgame.gameInfo.currentPlay.about.inning.toString()) : null;              
                }
                  console.log(game)
              }
            }
          })
        },
        error: async (err: any) => {
          console.log(err)
        },
        complete: () => console.log('game subscription complete')
      })
    )

    this.onCreateHubCommentsSubscription = <Subscription>(
      this.api.OnCreateHubCommentsListener().subscribe({
        next: async (event: any) => {
          const hubPostId = event.value.data.onCreateHubComments.HubPostsID;
          console.log(hubPostId)
          let commentsList: any = await this.api.ListHubComments({ HubPostsID: { eq: hubPostId } }).then((data) => data)

          console.log(commentsList)

          this.hubData.filter(values => {
            if (values.id === hubPostId) {
              console.log(values.comments)
              values.comments = commentsList
            }
          })
        }
      })
    )

    this.onDeleteHubCommentsSubscription = <Subscription>(
      this.api.OnDeleteHubCommentsListener().subscribe({
        next: async (event: any) => {
          const hubPostId = event.value.data.onDeleteHubComments.HubPostsID;
          let commentsList: any = await this.api.ListHubComments({ HubPostsID: { eq: hubPostId } }).then((data) => data)

          this.hubData.filter(values => {
            if (values.id === hubPostId) {
              values.comments = commentsList
            }
          })
        }
      })
    )


    this.onCreateHubLikesSubscription = <Subscription>(
      this.api.OnCreateHubLikesListener().subscribe({
        next: async (event: any) => {
          const hubPostId = event.value.data.onCreateHubLikes.hubPostID;
          let likesList: any = await this.api.ListHubLikes({ hubPostID: { eq: hubPostId } }).then((data) => data)

          this.hubData.filter(values => {
            if (values.id === hubPostId) {
              values.likes = likesList
            }
          })
        }
      })
    )

    this.onDeleteHubLikesSubscription = <Subscription>(
      this.api.OnDeleteHubLikesListener().subscribe({
        next: async (event: any) => {
          const hubPostId = event.value.data.onDeleteHubLikes.hubPostID;
          let likesList: any = await this.api.ListHubLikes({ hubPostID: { eq: hubPostId } }).then((data) => data)
          if (likesList) {
            this.hubData.filter(values => {
              if (values.id === hubPostId) {
                values.likes = likesList
              }
            })
          } else {
            this.hubData.filter(values => {
              if (values.id === hubPostId) {
                values.likes = []
              }
            })
          }

        }
      })
    )

  }

  async ionViewDidLeave() {
    if (this.onUpdateSportsGame) {
      await this.onUpdateSportsGame.unsubscribe();
    }
    if (this.onCreateHubLikesSubscription){
      await this.onCreateHubLikesSubscription.unsubscribe();
    }
    if (this.onDeleteHubLikesSubscription) {
      await this.onDeleteHubCommentsSubscription.unsubscribe();
    }
    if (this.onCreateHubCommentsSubscription) {
      await this.onCreateHubLikesSubscription.unsubscribe();
    }
    if (this.onDeleteHubCommentsSubscription) {
      await this.onDeleteHubCommentsSubscription.unsubscribe();
    }
    if (this.onUpdateHubPost) {
      await this.onUpdateHubPost.unsubscribe();
    }
    if (this.onDeleteHubPost) {
      await this.onDeleteHubPost.unsubscribe();
    }
    if (this.refreshHubAction){
      await this.refreshHubAction.unsubscribe();
    }
  }


  async getHubData(){
    await this.api.HubPostsBySortKeyAndTimePosted("hubpost", null, ModelSortDirection.DESC, null, 15).then(data => {
      this.hubData = data.items;
      this.nextToken = data.nextToken;

      this.hubData.map(async hubs => {
        if ((hubs.postType === 'sport' && hubs.sportsgame === null) || (hubs.postType === 'milestone' && hubs.sportsgame === null)){
          hubs.postDetails = await JSON.parse(hubs.postDetails)
        } else if(hubs.postType === 'sport' && hubs.sportsgame.sport === 'baseball'){
          hubs.sportsgame.gameInfo = await JSON.parse(hubs.sportsgame.gameInfo)
          hubs.sportsgame.liveGameChatRoomID = hubs.sportsgame.id.toString() + "-chatroom";
          this.pitcherInfo = Object.keys(hubs.sportsgame.gameInfo.initialGameData.probablePitchers).length;
          this.weatherInfo = Object.keys(hubs.sportsgame.gameInfo.initialGameData.weather).length

          if(Date.parse(hubs.sportsgame.startTime) > Date.now()){
            hubs.sportsgame.gameInfo.gameStarted = false;
          } else {
            hubs.sportsgame.gameInfo.gameStarted = true;
          }

          hubs.sportsgame.gameInfo.currentHalfInning = null;
          hubs.sportsgame.gameInfo.currentHalfInning = hubs.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' ? hubs.sportsgame.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(hubs.sportsgame.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", hubs.sportsgame.gameInfo.currentPlay.about.inning.toString()) : null;

          if (hubs.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress') {
            hubs.sportsgame.gameInfo.currentHalfInning = hubs.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' ? hubs.sportsgame.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(hubs.sportsgame.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", hubs.sportsgame.gameInfo.currentPlay.about.inning.toString()) : null;
          }
        }
      })
      console.log(this.hubData)
    })
  }

  async presentActionSheet(username, postId, imagePresent, imageSrc){
    if (this.currentUserProfileID === username) {
      const actionSheet = await this.actionSheetController.create({
        header: 'Post Settings',
        buttons: [{
          text: 'Edit Post',
          icon: 'pencil-outline',
          handler: () => {
            // this.clickEditPost(mediaKey, mediaId);
          }
        },
        {
          text: 'Delete Post',
          role: 'destructive',
          icon: 'trash-outline',
          handler: async () => {
            const alert = await this.alertController.create({
              header: 'Delete Post',
              message: 'Are you sure you want to delete this post?',
              buttons: [
                {
                  text: 'No',
                },
                {
                  text: 'Delete',
                  handler: async () => {

                    await this.api.DeleteHubPosts({id: postId})
                    if(imagePresent){
                      await Storage.remove('hubpost-photo/' + imageSrc)
                    }
                    await this.presentToastDeletePost();
                  }
                }
              ]
            });

            await alert.present();
          }
        }, {
          text: 'Share',
          icon: 'share-outline',
          handler: async () => {
            // const loading = await this.loadingController.create({
            //   spinner: 'lines-sharp-small',
            //   translucent: false,
            //   cssClass: 'spinner-loading'
            // });

            // loading.present();

            // let media = await this.api.GetImagePost(mediaId)
            // if (isVideo) {
            //   let video = await Storage.get(media.downloadableVideo, { bucket: "fetadevvodservice-dev-input-nk0sepbg" })

            //   if (this.platform.is('desktop' || 'mobileweb' || 'pwa')) {
            //     const fileName = 'feta-download-' + new Date().getTime() + '.mov'
            //     this.downloadBlob(video, fileName)
            //     loading.dismiss()
            //   }

              // if (this.platform.is('hybrid' || 'iphone' || 'ios' || 'mobile' || 'ipad')) {
              //   const response = await fetch(`${video}`)
              //   const blob = await response.blob();
              //   const base64Data = await this.convertBlobToBase64(blob) as string;
              //   const fileName = new Date().getTime() + '.mov';

              //   await Filesystem.writeFile({
              //     path: fileName,
              //     data: base64Data,
              //     directory: Directory.Cache
              //   }).then(() => {
              //     return Filesystem.getUri({
              //       directory: Directory.Cache,
              //       path: fileName
              //     });
              //   }).then((uriResult) => {
              //     loading.dismiss()
              //     return Share.share({
              //       title: 'Share content',
              //       text: "Look at this content from Zach & Katie's Feta app",
              //       url: uriResult.uri,
              //       dialogTitle: 'Share this content',
              //     });
              //   })
              // }
            // } else {

            //   let photo = await Storage.get(mediaKey, { download: true })

            //   if (this.platform.is('desktop' || 'mobileweb' || 'pwa')) {
            //     const fileName = 'feta-download-' + new Date().getTime() + '.jpeg'
            //     this.downloadBlob(photo.Body, fileName)
            //     loading.dismiss();
            //   }

            //   if (this.platform.is('hybrid' || 'iphone' || 'ios' || 'mobile' || 'ipad')) {
            //     const base64Data = await this.readAsBase64(photo.Body)
            //     const fileName = new Date().getTime() + '.jpeg';

            //     await Filesystem.writeFile({
            //       path: fileName,
            //       data: base64Data,
            //       directory: Directory.Cache
            //     }).then(() => {
            //       return Filesystem.getUri({
            //         directory: Directory.Cache,
            //         path: fileName
            //       });
            //     }).then((uriResult) => {
            //       loading.dismiss();
            //       return Share.share({
            //         title: 'Share content',
            //         text: "Look at this content from Zach & Katie's Feta app",
            //         url: uriResult.uri,
            //         dialogTitle: 'Share this content',
            //       });
            //     })
            //   }
            // }
          }
        }, {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => { }
        }]
      });
      await actionSheet.present();

      // const { role } = await actionSheet.onDidDismiss();

    // } else {
      // const actionSheet = await this.actionSheetController.create({
      //   header: 'Post Settings',
      //   cssClass: 'my-custom-class',
      //   buttons: [{
      //     text: 'Share',
      //     icon: 'share-outline',
      //     handler: () => {

      //       this.shareButtonClicked(mediaId, isVideo, mediaKey)

      //     }
      //   },
      //   {
      //     text: 'Cancel',
      //     icon: 'close',
      //     role: 'cancel',
      //     handler: () => { }
      //   }]
      // });
      // await actionSheet.present();

      const { role } = await actionSheet.onDidDismiss();

    // }
  }

  }

  async presentToastDeletePost() {
    const toast = await this.toastController.create({
      message: 'Your post has been successfully deleted.',
      duration: 2000,
      position: "top",
      color: "success"
    });
    toast.present();
  }


  async loadData(event) {
    try {
      if (this.nextToken !== null) {
        this.api.HubPostsBySortKeyAndTimePosted("hubpost", null, ModelSortDirection.DESC, null, 15, this.nextToken).then((data) => {
          let dataPull: any = data.items;
          this.nextToken = data.nextToken;

          if(this.nextToken === null){
            this.scrollFinished = true;
            event.target.complete();
          } else {
            dataPull.map(async hubs => {
              if (hubs.postType === 'sport' && hubs.sportsgame === null) {
                hubs.postDetails = await JSON.parse(hubs.postDetails)
              } else if (hubs.postType === 'sport' && hubs.sportsgame.sport === 'baseball') {
                hubs.sportsgame.gameInfo = await JSON.parse(hubs.sportsgame.gameInfo)
                this.pitcherInfo = Object.keys(hubs.sportsgame.gameInfo.initialGameData.probablePitchers).length;
                this.weatherInfo = Object.keys(hubs.sportsgame.gameInfo.initialGameData.weather).length
  
                if (Date.parse(hubs.sportsgame.startTime) > Date.now()) {
                  hubs.sportsgame.gameInfo.gameStarted = false;
                } else {
                  hubs.sportsgame.gameInfo.gameStarted = true;
                }
  
                hubs.sportsgame.gameInfo.currentHalfInning = null;
                hubs.sportsgame.gameInfo.currentHalfInning = hubs.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' ? hubs.sportsgame.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(hubs.sportsgame.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", hubs.sportsgame.gameInfo.currentPlay.about.inning.toString()) : null;
  
                if (hubs.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress') {
                  hubs.sportsgame.gameInfo.currentHalfInning = hubs.sportsgame.gameInfo.initialGameData.status.detailedState === 'In Progress' ? hubs.sportsgame.gameInfo.currentPlay.about.halfInning.charAt(0).toUpperCase().concat(hubs.sportsgame.gameInfo.currentPlay.about.halfInning.slice(1, 3), " ", hubs.sportsgame.gameInfo.currentPlay.about.inning.toString()) : null;
                }
              }
            })
            Object.entries(dataPull).forEach(([key, value]) => { this.hubData[this.hubData.length] = value })
            event.target.complete();
          }
        })
      } else {
        this.scrollFinished = true;
        event.target.complete();
      }
    } catch (error) {
      console.log(error)
    }
  }
}

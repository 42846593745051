<ion-header>
  <ion-toolbar mode="ios">
    <ion-title>App Rules</ion-title>
    <ion-note color="tertiary" slot="start" (click)="backToWall()" class="cancel">&nbsp;&nbsp;<ion-icon
        name="chevron-back-outline"></ion-icon>Back</ion-note>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-note>These are the rules...don't be a jerk.</ion-note>

</ion-content>
